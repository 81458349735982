import React, { useState } from 'react';
import {
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  FormErrorMessage,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useFormContext } from 'react-hook-form';

const PasswordInput = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => setShowPassword(!showPassword);

  return (
    <FormControl isInvalid={!!errors.password} mb='xl'>
      <InputGroup>
        <Input
          {...register('password', {
            required: 'This field is required',
          })}
          id='password'
          placeholder='Password'
          type={showPassword ? 'text' : 'password'}
        />
        <InputRightElement>
          <IconButton
            mr='sm'
            _focus={{ bg: 'transparent' }}
            _hover={{ bg: 'transparent' }}
            bg='transparent'
            aria-label={showPassword ? 'Hide password' : 'Show password'}
            icon={
              showPassword ? (
                <ViewOffIcon fontSize='md' />
              ) : (
                <ViewIcon fontSize='md' />
              )
            }
            onClick={handleTogglePassword}
            size='sm'
          />
        </InputRightElement>
      </InputGroup>
      {errors.password && (
        <FormErrorMessage>{errors.password.message as string}</FormErrorMessage>
      )}
    </FormControl>
  );
};

export default PasswordInput;
