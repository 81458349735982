import React, { FC, PropsWithChildren } from 'react';

import { Box, Flex, useColorMode, useTheme } from '@chakra-ui/react';

import { Header } from '@/components/Layout';

const Page: FC<
  PropsWithChildren<{
    withHeader?: boolean;
  }>
> = ({ children, withHeader = false, ...rest }) => {
  const { colorMode } = useColorMode();
  const theme = useTheme();
  const navHeight = withHeader ? theme.header.height : 0;

  const mainBgColor =
    colorMode === 'light'
      ? 'brand.backgroundLightSecondary'
      : 'brand.backgroundDarkSecondary';

  return (
    <Box
      pt={{
        base: '0',
        md: `${navHeight}px`,
      }}
      {...rest}
      height='100%'
      display={'flex'}
      flexDirection='column'
    >
      {withHeader && (
        <Box display={{ base: 'none', md: 'block' }}>
          <Header />
        </Box>
      )}
      <Flex flex='1' overflow='hidden'>
        <Box
          flexGrow={1}
          width='100%'
          overflowX='auto'
          marginBottom={{
            md: '0',
          }}
          bgColor={mainBgColor}
        >
          {children}
        </Box>
      </Flex>
    </Box>
  );
};

export default Page;
