import { BoxProps } from '@chakra-ui/react';

import SectionColumn from './SectionColumn';
import SectionContent from './SectionContent';
import SectionRow from './SectionRow';

type SectionProps = BoxProps & {
  columnProps?: BoxProps;
  contentProps?: BoxProps;
  children: React.ReactNode;
};

export default function Section(props: SectionProps) {
  const { children, columnProps, contentProps, ...rest } = props;
  return (
    <SectionRow {...rest}>
      <SectionColumn {...columnProps}>
        <SectionContent {...contentProps}>{children}</SectionContent>
      </SectionColumn>
    </SectionRow>
  );
}
