import { Box } from '@chakra-ui/react';
import loadable from '@loadable/component';
import { FC } from 'react';

type ImpactLoopLogoProps = {
  variant: Variant;
  width?: string;
  height?: string;
};

const logoMap = {
  symbolGreen: loadable(() => import('@/svgs/IL BrandSymbol Green.svg')),
  symbolWhite: loadable(() => import('@/svgs/IL BrandSymbol White.svg')),
  logoLight: loadable(() => import('@/svgs/ImpactLoop Logo 01.svg')),
  logoDark: loadable(() => import('@/svgs/ImpactLoop Logo 02.svg')),
};

type Variant = keyof typeof logoMap;

export const ImpactLoopLogo: FC<ImpactLoopLogoProps> = ({
  variant,
  width = 'auto',
  height = 'auto',
}) => {
  const SvgComponent = logoMap[variant];
  return (
    <Box w={width} h={height}>
      <SvgComponent />
    </Box>
  );
};
