import React from 'react';
import {
  useTheme,
  chakra,
  Box,
  Flex,
  Spacer,
  useColorMode,
} from '@chakra-ui/react';
import { Button, Link } from '@/elements';
import useAutoHideHeader from './useAutoHideHeader';
import { ImpactLoopLogo } from '@/components/Icons/ImpactLoopLogo';
import { PATHS } from '@/lib/constants/paths';
import { useAuth } from '@/context/AuthContext';
import { useRouter } from 'next/router';

const HeaderContainer = chakra(Box, {
  baseStyle: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 'overlay',
    display: 'flex',
    alignItems: 'center',
  },
});

export default function Header() {
  const { colorMode } = useColorMode();
  const router = useRouter();
  const { self } = useAuth();
  const theme = useTheme();
  const navHeight = theme.header.height;

  const { nav } = useAutoHideHeader({
    navHeight,
  });

  const bgColor =
    colorMode === 'light'
      ? 'brand.backgroundLightPrimary'
      : 'brand.backgroundDarkPrimary';

  const logoVariant = colorMode === 'light' ? 'logoDark' : 'logoLight';

  return (
    <HeaderContainer
      ref={nav as React.MutableRefObject<HTMLElement>}
      background={bgColor}
      height={`${navHeight}px`}
      p='md'
    >
      <Link href={PATHS.homepage} mx='8px' display='block'>
        <ImpactLoopLogo variant={logoVariant} width='10rem' />
      </Link>

      <Spacer />
      <Flex justifyContent='flex-end' alignItems='flex-end'>
        {self ? null : (
          <Button
            variant='primary'
            size='sm'
            onClick={() => router.push(PATHS.signIn)}
          >
            Sign In
          </Button>
        )}
      </Flex>
    </HeaderContainer>
  );
}
