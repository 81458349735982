import React from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Card,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { ChevronRightIcon } from '@chakra-ui/icons';
import Link from 'next/link';
import { HomeIcon } from '../Icons/Home';
import { CustomIcon } from '../Icons/CustomIcon';
import { PATHS, ROUTENAMES } from '@/lib/constants/paths';

const Breadcrumbs = () => {
  const router = useRouter();
  const pathnames = router.pathname.split('/').filter((x) => x);

  return (
    <Card m='md' variant='filled'>
      <Breadcrumb spacing='8px' separator={<ChevronRightIcon />}>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} href={PATHS.homepage}>
            <CustomIcon as={HomeIcon} size='md' />
          </BreadcrumbLink>
        </BreadcrumbItem>
        {pathnames.map((value, index) => {
          const href = `/${pathnames.slice(0, index + 1).join('/')}`;
          const isLast = index === pathnames.length - 1;
          const routeName = ROUTENAMES[value] || value;

          return (
            <BreadcrumbItem key={index} isCurrentPage={isLast}>
              {isLast ? (
                <BreadcrumbLink
                  color={'brand.brightGreen'}
                  textDecoration='underline'
                >
                  {routeName}
                </BreadcrumbLink>
              ) : (
                <BreadcrumbLink
                  color={'brand.brightGreen'}
                  textDecoration='underline'
                  as={Link}
                  href={href}
                >
                  {routeName}
                </BreadcrumbLink>
              )}
            </BreadcrumbItem>
          );
        })}
      </Breadcrumb>
    </Card>
  );
};

export default Breadcrumbs;
