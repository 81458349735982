import React from 'react';
import { useTheme, Icon } from '@chakra-ui/react';

const sizeMap = {
  sm: '16px',
  md: '24px',
  lg: '32px',
  xl: '48px',
};

export const CustomIcon = ({ as, size = 'md', ...props }) => {
  const theme = useTheme();
  const iconSize = theme.sizes.icon[size] || sizeMap[size] || sizeMap['md'];

  return <Icon as={as} boxSize={iconSize} {...props} />;
};
