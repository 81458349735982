import { createIcon } from '@chakra-ui/icons';

export const HomeIcon = createIcon({
  displayName: 'HomeIcon',
  viewBox: '0 0 24 24',
  path: (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_657_3863)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.7071 2.29289C12.3166 1.90237 11.6834 1.90237 11.2929 2.29289L4.29325 9.29254L4.29254 9.29325L2.29289 11.2929C1.90237 11.6834 1.90237 12.3166 2.29289 12.7071C2.68342 13.0976 3.31658 13.0976 3.70711 12.7071L4 12.4142V19C4 20.6569 5.34315 22 7 22H17C18.6569 22 20 20.6569 20 19V12.4142L20.2929 12.7071C20.6834 13.0976 21.3166 13.0976 21.7071 12.7071C22.0976 12.3166 22.0976 11.6834 21.7071 11.2929L12.7071 2.29289Z'
          fill='#00F2A4'
        />
        <path
          d='M9 17C9 16.4477 9.44772 16 10 16H14C14.5523 16 15 16.4477 15 17V22H9V17Z'
          fill='#202020'
        />
      </g>
      <defs>
        <clipPath id='clip0_657_3863'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  ),
});
